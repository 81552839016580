import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1755b0d6 = () => interopDefault(import('../../src/insights/pages/campaigns/index.vue' /* webpackChunkName: "pages/campaigns/index" */))
const _e56315bc = () => interopDefault(import('../../src/insights/pages/widgets/index.vue' /* webpackChunkName: "pages/widgets/index" */))
const _50f04908 = () => interopDefault(import('../../src/insights/pages/auth/callback.vue' /* webpackChunkName: "pages/auth/callback" */))
const _0fc2fec2 = () => interopDefault(import('../../src/insights/pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _6b334890 = () => interopDefault(import('../../src/insights/pages/reports/_hash/index.vue' /* webpackChunkName: "pages/reports/_hash/index" */))
const _46b4aefc = () => interopDefault(import('../../src/insights/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/campaigns",
    component: _1755b0d6,
    name: "campaigns"
  }, {
    path: "/widgets",
    component: _e56315bc,
    name: "widgets"
  }, {
    path: "/auth/callback",
    component: _50f04908,
    name: "auth-callback"
  }, {
    path: "/auth/login",
    component: _0fc2fec2,
    name: "auth-login"
  }, {
    path: "/reports/:hash",
    component: _6b334890,
    name: "reports-hash"
  }, {
    path: "/",
    component: _46b4aefc,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
