import { ActionTree, MutationTree, ActionContext } from 'vuex';
import Moment from 'moment';
import { deepEqual } from 'fast-equals';
import cloneDeep from 'lodash.clonedeep';

export interface IGroup {
  id: string;
  name: string;
  reportSettings?: {
    domain: string[];
  };
}

export interface ICampaign {
  id: string;
  name: string;
  advertiser: any;
}

export interface IStatistic {
  revenue: number | null;
  impressions: number | null;
  clicks: number | null;
  ctr: number | null;
  ecpm: number | null;
}

interface IStatisticsWithDate extends IStatistic {
  date: string | null;
}

interface IFilters {
  selectedGroup: IGroup | null;
  selectedCampaign: ICampaign | null;
  selectedDateRange: Date[];
  selectedGroupBy: string;
  selectedChildIds: string[];
  selectedResolution: string;
  meta: {
    selectedDateRangeHumanReadable: string;
  };
}

interface IState {
  filters: IFilters;
  activeFilters: IFilters;
  statistics: {
    total: IStatistic[];
    totalDaily: IStatisticsWithDate[];
    dailyGroupedBy: IStatisticsWithDate[];
    totalGroupedBy: IStatistic[];
  };
  lastUpdated: Moment.Moment | null;
  isFetchingAdvertiserStatistics: boolean;
  tableCurrency: string;
}

const initialState = {
  // Currently active filters, hence the ones we have fetched data for
  activeFilters: {
    selectedDateRange: [],
    selectedGroup: null,
    selectedCampaign: null,
    selectedGroupBy: 'campaign',
    selectedResolution: 'total',
    selectedChildIds: [],
    meta: {
      selectedDateRangeHumanReadable: '',
    },
  },
  // Currently selected filters
  filters: {
    selectedDateRange: [],
    selectedGroup: null,
    selectedCampaign: null,
    selectedGroupBy: 'campaign',
    selectedResolution: 'total',
    selectedChildIds: [],
    meta: {
      selectedDateRangeHumanReadable: '',
    },
  },
  statistics: {
    dailyGroupedBy: [],
    totalGroupedBy: [],
    totalDaily: [],
    total: [],
  },
  isFetchingAdvertiserStatistics: false,
  lastUpdated: null,
  tableCurrency: '',
};

export const state = (): IState => {
  return initialState;
};

export const getters = {
  initialized: (state: IState): boolean => {
    return (
      state.filters.selectedCampaign !== null &&
      state.filters.selectedGroup !== null
    );
  },
  filtersChanged: (state: IState): boolean => {
    return !deepEqual(state.filters, state.activeFilters);
  },
  selectedChildIds: (state: IState): string[] => {
    return state.filters.selectedChildIds || [];
  },
  sumOfTotal:
    (state: IState) =>
    (field: string): any => {
      return (state.statistics.total || []).reduce((acc, curr) => {
        return acc + curr[field];
      }, 0);
    },
  averageOfTotal:
    (state: IState) =>
    (field: string): any => {
      let count = 0;
      const sum = (state.statistics.total || []).reduce((acc, curr) => {
        count++;
        return acc + curr[field];
      }, 0);
      return count > 0 ? sum / count : 0;
    },
};

export const mutations: MutationTree<IState> = {
  SET_SELECTED_DATE_RANGE: (state: IState, val: Date[]) => {
    state.filters.selectedDateRange = val;
  },
  SET_SELECTED_DATE_RANGE_HUMAN_READABLE: (
    state: IState,
    humanReadable: string
  ) => {
    state.filters.meta.selectedDateRangeHumanReadable = humanReadable;
  },
  SET_SELECTED_GROUP: (state: IState, val: IGroup) => {
    state.filters.selectedGroup = val;
  },
  SET_SELECTED_CAMPAIGN: (state: IState, val: ICampaign) => {
    state.filters.selectedCampaign = val;
  },
  SET_SELECTED_GROUP_BY: (state: IState, val: string) => {
    state.filters.selectedGroupBy = val;
  },
  SET_SELECTED_RESOLUTION: (state: IState, val: string) => {
    state.filters.selectedResolution = val;
  },
  SET_ADVERTISER_STATISTICS: (state: IState, val: any) => {
    state.statistics = Object.assign({}, state.statistics, val);
  },
  SET_SELECTED_CHILD_IDS: (state: IState, val: string[]) => {
    state.filters.selectedChildIds = val || [];
  },
  SET_LAST_UPDATED: (state: IState, val: string) => {
    state.lastUpdated = Moment(val);
  },
  SET_FETCHING_ADVERTISER_STATISTICS: (state: IState, val: boolean) => {
    state.isFetchingAdvertiserStatistics = val;
  },
  SET_ACTIVE_FILTERS: (state: IState, val: IFilters | null) => {
    if (val) {
      state.activeFilters = cloneDeep(val);
    } else {
      state.activeFilters = cloneDeep(state.filters);
    }
  },
  SET_TABLE_CURRENCY: (state: IState, val: string) => {
    state.tableCurrency = val;
  },
};

export const actions: ActionTree<IState, IState> = {
  setSelectedDateRange: (
    { commit }: ActionContext<IState, IState>,
    payload: { val: Date[]; humanReadable: string }
  ) => {
    commit('SET_SELECTED_DATE_RANGE', payload.val);
    commit('SET_SELECTED_DATE_RANGE_HUMAN_READABLE', payload.humanReadable);
  },
  setSelectedGroup: (
    { commit }: ActionContext<IState, IState>,
    val: IGroup
  ) => {
    commit('SET_SELECTED_GROUP', val);
    commit('SET_SELECTED_CHILD_IDS', []);
  },
  setSelectedCampaign: (
    { commit }: ActionContext<IState, IState>,
    val: ICampaign
  ) => {
    commit('SET_SELECTED_CAMPAIGN', val);
    commit('SET_SELECTED_CHILD_IDS', []);
  },
  setSelectedGroupBy: (
    { commit }: ActionContext<IState, IState>,
    val: boolean
  ) => {
    commit('SET_SELECTED_CHILD_IDS', []);
    commit('SET_SELECTED_GROUP_BY', val);
  },
  setSelectedChildIds: (
    { commit, dispatch }: ActionContext<IState, IState>,
    val: string[]
  ) => {
    commit('SET_SELECTED_CHILD_IDS', val);
  },
  setFetchingAdvertiserStatistics: (
    { commit }: ActionContext<IState, IState>,
    val: boolean
  ) => {
    commit('SET_FETCHING_ADVERTISER_STATISTICS', val);
  },
};
