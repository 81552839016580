import { ActionTree, MutationTree, ActionContext } from 'vuex';
import Moment from 'moment';
import { deepEqual } from 'fast-equals';

export interface IGroup {
  id: string;
  name: string;
}

export interface IWidget {
  id: string;
  name: string;
}

export interface IStatistic {
  date: string;
  widgetName: string | null;
  widgetId: string | null;
  revenueLocalCurrency: number;
  localCurrency: string;
  widgetImpressions: number;
  widgetCtr: number;
  totalClicks: number;
  articleClicks: number;
  adClicks: number;
  houseAdClicks: number;
  totalImpressions: number;
  articleImpressions: number;
  adImpressions: number;
  houseAdImpressions: number;
  articleCtr: number;
  adCtr: number;
  houseAdCtr: number;
}

const validGroupBy = ['total', 'widget'];
const validView = ['day', 'month'];

interface IFilters {
  selectedGroup: IGroup | null;
  selectedWidgets: IWidget[];
  dateRange: Date[];
  groupBy: string;
  view: string;
  includeArchivedWidgets: boolean;
}

export interface IDescriptions {
  groupBy: string;
  view: string;
  groupName: string;
  widgetNames: string[];
  includeArchivedWidgets: boolean;
}

interface IState {
  initialFilters: IFilters;
  filters: IFilters;
  statistics: IStatistic[];
  clearWidgets: boolean;
  includeArchivedWidgets: boolean;
  lastUpdated: Moment.Moment | null;
  showingCount: number;
  groupsInitialized: boolean;
  widgetsInitialized: boolean;
  fetchingStatistics: boolean;
  fetchedDataDescriptions: IDescriptions;
}

export const state = (): IState => {
  return {
    initialFilters: {
      dateRange: [],
      selectedGroup: null,
      selectedWidgets: [],
      groupBy: 'total',
      view: 'day',
      includeArchivedWidgets: false,
    },
    filters: {
      dateRange: [],
      selectedGroup: null,
      selectedWidgets: [],
      groupBy: 'total',
      view: 'day',
      includeArchivedWidgets: false,
    },
    groupsInitialized: false,
    widgetsInitialized: false,
    fetchingStatistics: false,
    clearWidgets: false,
    includeArchivedWidgets: false,
    statistics: [],
    lastUpdated: null,
    showingCount: 0,
    fetchedDataDescriptions: {
      groupBy: 'total',
      view: 'day',
      groupName: '',
      widgetNames: [],
      includeArchivedWidgets: false,
    },
  };
};

export const getters = {
  initialized: (state: IState): boolean => {
    return state.groupsInitialized && state.widgetsInitialized;
  },
  filtersChanged: (state: IState): boolean => {
    return !deepEqual(state.filters, state.initialFilters);
  },
  statisticsCount: (state: IState): number => {
    return state.statistics.length;
  },
};

export const mutations: MutationTree<IState> = {
  SET_DATE_RANGE: (state: IState, val: Date[]) => {
    state.filters.dateRange = val;
  },
  SET_SELECTED_GROUP: (state: IState, val: IGroup) => {
    state.filters.selectedGroup = val;
    state.groupsInitialized = true;
  },
  SET_SELECTED_WIDGET: (state: IState, val: IWidget[]) => {
    state.filters.selectedWidgets = val;
  },
  SET_GROUP_BY: (state: IState, val: string) => {
    state.filters.groupBy = val;
  },
  SET_INCLUDE_ARCHIVED_WIDGETS: (state: IState, val: boolean) => {
    state.filters.includeArchivedWidgets = val;
  },
  SET_VIEW: (state: IState, val: string) => {
    state.filters.view = val;
  },
  SET_CLEAR_WIDGETS: (state: IState, val: boolean) => {
    state.clearWidgets = val;
  },
  SET_STATISTICS: (state: IState, val: IStatistic[]) => {
    state.statistics = val;
  },
  SET_LAST_UPDATED: (state: IState, val: string) => {
    state.lastUpdated = Moment(val);
  },
  SET_SHOWING_COUNT: (state: IState, val: number) => {
    state.showingCount = val;
  },
  SET_WIDGETS_INITIALIZED: (state: IState, val: boolean) => {
    state.widgetsInitialized = val;
  },
  SET_FETCHING_STATISTICS: (state: IState, val: boolean) => {
    state.fetchingStatistics = val;
  },
  SET_INITIAL_FILTERS: (state: IState, val: IFilters) => {
    state.initialFilters = JSON.parse(JSON.stringify(val));
    state.initialFilters.dateRange = [val.dateRange[0], val.dateRange[1]];
  },
  SET_FETCHED_DATA_DESCRIPTIONS: (state: IState, val: IDescriptions) => {
    state.fetchedDataDescriptions = val;
  },
};

export const actions: ActionTree<IState, IState> = {
  setDateRange: ({ commit }: ActionContext<IState, IState>, val: Date[]) => {
    commit('SET_DATE_RANGE', val);
  },
  setSelectedGroup: (
    { commit }: ActionContext<IState, IState>,
    val: IGroup
  ) => {
    commit('SET_SELECTED_GROUP', val);
  },
  setSelectedWidgets: (
    { commit }: ActionContext<IState, IState>,
    val: IWidget[]
  ) => {
    commit('SET_SELECTED_WIDGET', val);
  },
  setGroupBy: ({ commit }: ActionContext<IState, IState>, val: string) => {
    if (validGroupBy.includes(val)) {
      commit('SET_GROUP_BY', val);
    }
  },
  setIncludeArchivedWidgets: (
    { commit }: ActionContext<IState, IState>,
    val: boolean
  ) => {
    commit('SET_INCLUDE_ARCHIVED_WIDGETS', val);
  },
  setView: ({ commit }: ActionContext<IState, IState>, val: string) => {
    if (validView.includes(val)) {
      commit('SET_VIEW', val);
    }
  },
  setClearWidgets: (
    { commit }: ActionContext<IState, IState>,
    val: boolean
  ) => {
    commit('SET_CLEAR_WIDGETS', val);
  },
  setWidgetInitialized(
    { commit }: ActionContext<IState, IState>,
    val: boolean
  ) {
    commit('SET_WIDGETS_INITIALIZED', val);
  },
  setFetchingStatistics: (
    { commit }: ActionContext<IState, IState>,
    val: boolean
  ) => {
    commit('SET_FETCHING_STATISTICS', val);
  },
  setFetchedDataDescriptions: ({
    commit,
    state,
  }: ActionContext<IState, IState>) => {
    const descriptions: IDescriptions = {
      groupBy: state.filters.groupBy,
      groupName: state.filters.selectedGroup
        ? state.filters.selectedGroup.name
        : '',
      widgetNames: state.filters.selectedWidgets.map((w) => w.name),
      view: state.filters.view,
      includeArchivedWidgets: state.filters.includeArchivedWidgets,
    };
    commit('SET_FETCHED_DATA_DESCRIPTIONS', descriptions);
  },
};
