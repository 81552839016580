import Vue from 'vue'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import {
  FontAwesomeLayers,
  FontAwesomeLayersText,
  FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'

import {
  faAngleLeft as proFasFaAngleLeft,
  faAngleRight as proFasFaAngleRight,
  faTriangleExclamation as proFasFaTriangleExclamation,
  faCheck as proFasFaCheck,
  faPencil as proFasFaPencil,
  faEllipsis as proFasFaEllipsis,
  faCircleExclamation as proFasFaCircleExclamation,
  faCircleXmark as proFasFaCircleXmark,
  faCircleInfo as proFasFaCircleInfo,
  faCircleQuestion as proFasFaCircleQuestion,
  faCirclePause as proFasFaCirclePause,
  faCirclePlay as proFasFaCirclePlay,
  faXmark as proFasFaXmark,
  faBars as proFasFaBars,
  faMagnifyingGlass as proFasFaMagnifyingGlass,
  faBoxArchive as proFasFaBoxArchive,
  faSpinner as proFasFaSpinner,
  faPlus as proFasFaPlus,
  faTrash as proFasFaTrash,
  faClipboardCheck as proFasFaClipboardCheck,
  faArrowUpRightFromSquare as proFasFaArrowUpRightFromSquare,
  faChevronDown as proFasFaChevronDown,
  faCloudArrowUp as proFasFaCloudArrowUp,
  faList as proFasFaList,
  faChartPie as proFasFaChartPie,
  faImages as proFasFaImages
} from '@fortawesome/pro-solid-svg-icons'

import {
  faClipboard as proFarFaClipboard,
  faCircleQuestion as proFarFaCircleQuestion
} from '@fortawesome/pro-regular-svg-icons'

import {
  faGrid2 as proFalFaGrid2,
  faChartSimple as proFalFaChartSimple
} from '@fortawesome/pro-light-svg-icons'

library.add(
  proFasFaAngleLeft,
  proFasFaAngleRight,
  proFasFaTriangleExclamation,
  proFasFaCheck,
  proFasFaPencil,
  proFasFaEllipsis,
  proFasFaCircleExclamation,
  proFasFaCircleXmark,
  proFasFaCircleInfo,
  proFasFaCircleQuestion,
  proFasFaCirclePause,
  proFasFaCirclePlay,
  proFasFaXmark,
  proFasFaBars,
  proFasFaMagnifyingGlass,
  proFasFaBoxArchive,
  proFasFaSpinner,
  proFasFaPlus,
  proFasFaTrash,
  proFasFaClipboardCheck,
  proFasFaArrowUpRightFromSquare,
  proFasFaChevronDown,
  proFasFaCloudArrowUp,
  proFasFaList,
  proFasFaChartPie,
  proFasFaImages,
  proFarFaClipboard,
  proFarFaCircleQuestion,
  proFalFaGrid2,
  proFalFaChartSimple
)

config.autoAddCss = false

Vue.component('fa-icon', FontAwesomeIcon)
Vue.component('fa-iconLayers', FontAwesomeLayers)
Vue.component('fa-iconLayersText', FontAwesomeLayersText)
